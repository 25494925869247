import { HeroSearchForm } from "components/HeroSearchForm/HeroSearchForm";
import { HeroSearchFormMobile } from "components/HeroSearchForm/HeroSearchFormMobile";
import { FC } from "react";
import { LinearGradient } from "react-text-gradients";

export interface SectionHero4Props {
  className?: string;
}

const SectionHero4: FC<SectionHero4Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center">
        <div className="flex-shrink-0 flex flex-col items-start pb-14 lg:pb-48 xl:pr-14 lg:mr-10 xl:mr-0">
          <h2 className="font-bold text-3xl md:text-4xl xl:text-5xl !leading-[114%] text-white">
            Experience Tampa With <br className="hidden md:block" />
            <span className="md:hidden flex text-[#D242D7] drop-shadow-[0_1.2px_1.2px_rgba(255,255,255,1)]">
              Pheobe
            </span>
            <div className="hidden md:flex">
              <LinearGradient gradient={["to left", "#07D6FE ,#FC15D7"]}>
                Pheobe
              </LinearGradient>
            </div>
            <br />
          </h2>
          <span className="text-white text-base font-normal">
            Your fresh solution for carshare and rideshare services.
          </span>
          <span className="text-base md:text-lg text-white flex"></span>
        </div>
      </div>

      <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
      </div>
    </div>
  );
};

export default SectionHero4;
