import { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useParams, useNavigate } from "react-router-dom";
import {
  cancelBooking,
  cancelBooking as cancelBookingAPI,
} from "api/booking/booking"; // Renamed import here

import {
  Transition,
  Dialog,
  TransitionChild,
  DialogPanel,
  DialogTitle,
  Button,
} from "@headlessui/react";
import { useData } from "data/data-provider";
import { retriveBooking } from "api/booking/booking";
import toast from "react-hot-toast";
import UploadZone from "components/upload/upload";
import { globalJson } from "global/global_json";
import { encodeBase64 } from "@progress/kendo-file-saver";
import { sendRentalAgreement } from "api/rental-agreement/rental-agreement";
import { XMarkIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import { CreateStripeVerification } from "api/stripe/stripe";
import { CreateCanopyVerification } from "api/Canopy/canopy";
import { CanopyPayload } from "api/types";
import Badge from "shared/Badge/Badge";

export interface bookingDetailPageProps {
  className?: string;
}

const BookingDetailPage: FC<bookingDetailPageProps> = ({ className = "" }) => {
  const { user, startDate, endDate } = useData();
  const { bookingId } = useParams();
  const [ignitionUrl, setIgnitionUrl] = useState<string | null>();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [isTotalBillModalOpen, setIsTotalBillModalOpen] = useState(false);
  let [isOpen, setIsOpen] = useState(false);
  let [bookingDetail, setBookingDetail] = useState<any | null>(null);
  const [isPreTrip, setIsPreTrip] = useState<boolean>(true);

  const params = useParams();
  const modifyBooking = async (bookingId: string) => {
    navigate(`/modify-booking/${bookingId}`);
    return;
  };

  const handleOpenTotalBillModal = () => {
    setIsTotalBillModalOpen(true);
  };

  const handleCloseTotalBillModal = () => {
    setIsTotalBillModalOpen(false);
  };

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }
  const handleCancelBooking = async (bookingId: string) => {
    setLoading(true);
    try {
      const { data, error } = await cancelBooking(bookingId);

      if (error) {
        toast.error(`Error cancelling booking: ${error}`);
      } else {
        toast.success("Booking cancelled successfully.");
        navigate("/account-bookings");
      }
    } catch (err) {
      toast.error("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const openRentalAgreement = async () => {
    if (params?.bookingId) {
      const encodedData = encodeBase64(bookingDetail?.customer?.email);
      const path = `${bookingDetail?.id}b6cr4${
        process.env.REACT_APP_ENV !== "prod"
          ? globalJson.dev_company_id
          : globalJson.prod_company_id
      }b6cr4${encodedData}`;

      const url =
        process.env.REACT_APP_ENV !== "dev"
          ? "https://1now.app/pdf?agreement="
          : "https://dev.1now.app/pdf?agreement=";
      window.open(`${url}${path}`, "_blank");
    }
  };

  const sendAgreementEmail = async (type: string) => {
    const encodedData = encodeBase64(bookingDetail?.customer?.email as string);

    const path = `${bookingDetail?.id}b6cr4${
      process.env.REACT_APP_ENV !== "prod"
        ? globalJson.dev_company_id
        : globalJson.prod_company_id
    }b6cr4${encodedData}`;
    try {
      const data: any = await sendRentalAgreement(path, type);
      toast.success("Email successfully sent!");
    } catch (error: any) {
      toast.error(error.toString());
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchBookingDetail = async () => {
      if (!bookingId) {
        toast.error("Booking ID is missing");
        setLoading(false);
        return;
      }

      try {
        const data = await retriveBooking(bookingId);

        if (data.error) {
          throw new Error(data.error); // Handle any error response here
        }

        setBookingDetail(data);
      } catch (error) {
        toast.error("Error fetching booking details.");
        setBookingDetail(null);
      } finally {
        setLoading(false); // End loading state after fetching data
      }
    };

    fetchBookingDetail();
  }, [bookingId]);

  const handleCanopyVerificationButtonClick = async () => {
    const payload: CanopyPayload = {
      user_id: bookingDetail?.customer?.id ?? "",
    };

    const IgnitionResponse = (await CreateCanopyVerification(payload)) as {
      data?: { insurance_verification_link?: string };
      error?: string;
    };

    if (
      IgnitionResponse.data &&
      IgnitionResponse.data.insurance_verification_link
    ) {
      const ignitionUrl = IgnitionResponse.data.insurance_verification_link;
      setIgnitionUrl(ignitionUrl);
      setLoading(false);

      // Open the ignition URL in a new browser tab/window
      window.open(ignitionUrl, "_blank");

      return;
    } else {
      // Handle the case where data or insurance_verification_link is undefined
      console.error(
        "Failed to retrieve insurance verification link:",
        IgnitionResponse.error
      );
      toast.error("Verification failed. Please try again later.");
      setLoading(false);
    }
  };

  const handlePrimaryDriverVerificationButtonClick = async () => {
    setLoading(true);
    const payload = { customer_id: bookingDetail?.customer?.id ?? "" };

    const stripeSessionResponse = (await CreateStripeVerification(payload)) as {
      data?: { session_url?: string };
      error?: string;
    };

    if (stripeSessionResponse.data && stripeSessionResponse.data.session_url) {
      const ignitionUrl = stripeSessionResponse.data.session_url;
      setIgnitionUrl(ignitionUrl);
      setLoading(false);

      // Open the ignition URL in a new browser tab/window
      window.open(ignitionUrl, "_blank");

      return;
    } else {
      // Handle the case where data or session_url is undefined
      console.error(
        "Failed to retrieve session URL:",
        stripeSessionResponse.error
      );
      toast.error("Verification failed. Please try again later.");
      setLoading(false);
    }
  };

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col space-y-8 ">
        <div className="flex justify-between">
          <h2 className="text-[18px] text-[#292D32] md:text-3xl font-bold">
            Booking Details
          </h2>
          <h2 className="hidden md:flex text-2xl font-semibold text-[#6B7280]">
            Booking ID: {bookingDetail?.id}
          </h2>
          <Badge
            className="flex md:hidden"
            color={
              bookingDetail?.trip_status === "pending" ? "yellow" : "green"
            }
            name={bookingDetail?.trip_status}
          />
        </div>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-6">
          <div className="flex justify-start flex-row items-center">
            <div className="w-48 min-h-[192px] mr-8">
              <div className="w-full aspect-w-7 aspect-h-7 rounded-2xl overflow-hidden">
                <img
                  alt=""
                  className="absolute inset-0 object-cover w-full"
                  src={
                    (bookingDetail?.fleet?.fleet_photos?.length ?? 0) > 0
                      ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${bookingDetail.fleet?.fleet_photos[0].photo_url}`
                      : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                  }
                />
              </div>
            </div>
            <div className="text-[#9AA3AD]">
              <span className="md:text-xl text-md "> Car</span>
              <h2 className="text-lg md:text-xl font-semibold items-center mb-2 text-black">
                {bookingDetail?.fleet?.name}
              </h2>
              <span className="md:text-xl text-md ">License plate number</span>
              <h2 className="text-lg md:text-xl font-semibold items-center mb-2 text-black">
                {bookingDetail?.fleet?.plate_number}
              </h2>
              {/* {bookingDetail?.trip_status !== "cancelled" && (
                <ButtonPrimary
                  className="bg-red-600 hover:bg-red-600"
                  onClick={() => {
                    handleCancelBooking(bookingDetail.id);
                  }}
                >
                  Cancel Booking
                </ButtonPrimary>
              )} */}
            </div>
          </div>

          <div className="flex justify-between">
            <div className="gap-2">
              <span className=" text-lg text-[#777777]">Pick Up</span>
              <br />
              <span className="mt-1.5 text-lg font-bold ">
                <span>{moment(startDate).format("ddd, D MMM YYYY")}</span>
                <br />
                <span className="font-normal">
                  {moment(startDate).format("hh:mm A")}
                </span>
              </span>
              <br />
              <span className="mt-1.5 text-lg  ">
                {bookingDetail?.custom_pick_up_address === ""
                  ? bookingDetail?.pick_up_location?.name
                  : bookingDetail?.custom_pick_up_address}
              </span>
              {/* <ButtonPrimary
                className="mt-1.5"
                onClick={() => {
                  const pickUpLat = bookingDetail?.pick_up_location?.latitude;
                  const pickUpLng = bookingDetail?.pick_up_location?.longitude;

                  if (pickUpLat && pickUpLng) {
                    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${pickUpLat},${pickUpLng}`;
                    window.open(googleMapsUrl, "_blank"); // Opens in a new tab
                  } else {
                    toast.error("Location coordinates are missing");
                  }
                }}
              >
                Get Directions
              </ButtonPrimary> */}
            </div>
            <div className="gap-2">
              <span className=" text-lg text-[#777777]">Drop Off</span>
              <br />
              <span className="mt-1.5 text-lg font-bold ">
                <span>{moment(endDate).format("ddd, D MMM YYYY")}</span>
                <br />
                <span className="font-normal">
                  {moment(endDate).format("hh:mm A")}
                </span>
              </span>
              <br />
              <span className="mt-1.5 text-lg">
                {bookingDetail?.custom_drop_off_address === ""
                  ? bookingDetail?.drop_off_location?.name
                  : bookingDetail?.custom_drop_off_address
                  ? bookingDetail?.custom_drop_off_address
                  : bookingDetail?.custom_pick_up_address}
              </span>
              {/* <ButtonPrimary
                className="mt-1.5"
                onClick={() => {
                  const dropOffLat = bookingDetail?.drop_off_location?.latitude;
                  const dropOffLng =
                    bookingDetail?.drop_off_location?.longitude;

                  if (dropOffLat && dropOffLng) {
                    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${dropOffLat},${dropOffLng}`;
                    window.open(googleMapsUrl, "_blank"); // Opens in a new tab
                  } else {
                    toast.error("Location coordinates are missing");
                  }
                }}
              >
                Get Directions
              </ButtonPrimary> */}
            </div>
          </div>
        </div>
        <div className="mt-5 grid grid-cols-1 gap-3 rounded-lg  p-4 @md:grid-cols-2 @lg:gap-4 @2xl:gap-5">
          <div>
            <div className="text-lg font-bold">Trip Photos</div>
          </div>
          <div className="col-span-full flex flex-col">
            <div className="flex cursor-pointer flex-row rounded-lg border p-1">
              <Button
                as="span"
                className={`w-[50%] border-0 text-xl leading-[1.8] rounded-lg  font-semibold sm:h-[35px] flex justify-center ${
                  isPreTrip === true && "bg-primary-200 text-white"
                }`}
                onClick={() => {
                  setIsPreTrip(true);
                }}
              >
                Pre Trip
              </Button>
              <Button
                as="span"
                className={`w-[50%] border-0  text-xl leading-[1.8] rounded-lg  font-semibold sm:h-[35px] flex justify-center ${
                  !isPreTrip && "bg-primary-200 text-white"
                }`}
                onClick={() => {
                  setIsPreTrip(false);
                }}
              >
                Post Trip
              </Button>
            </div>
          </div>
          {isPreTrip ? (
            <UploadZone bookingId={bookingDetail?.id} preTrip={isPreTrip} />
          ) : (
            <UploadZone bookingId={bookingDetail?.id} preTrip={isPreTrip} />
          )}
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-between gap-0 md:gap-12">
          {" "}
          <div className=" w-full">
            <div className="flex flex-col">
              <div className=" mb-6 md:mb-0">
                <h2 className="text-lg font-bold mb-8">Trip Info</h2>
                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">Agreement</p>
                  </div>
                  <div
                    onClick={openRentalAgreement}
                    className="text-gray-800 cursor-pointer mx-2"
                  >
                    <div className="flex text-primary-200 font-semibold">
                      View Agreement
                      <svg
                        width="23"
                        height="26"
                        viewBox="0 0 23 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.5253 19.7222C13.3481 19.7222 13.1709 19.6488 13.031 19.4917C12.7605 19.1879 12.7605 18.685 13.031 18.3812L18.1977 12.5774L13.031 6.77361C12.7605 6.4698 12.7605 5.96694 13.031 5.66313C13.3014 5.35932 13.7491 5.35932 14.0195 5.66313L19.6806 12.0222C19.951 12.326 19.951 12.8288 19.6806 13.1327L14.0195 19.4917C13.8797 19.6488 13.7025 19.7222 13.5253 19.7222Z"
                          fill="#90A3BF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">Total Bill</p>
                  </div>
                  <div
                    onClick={handleOpenTotalBillModal}
                    className="text-gray-800 cursor-pointer mx-2"
                  >
                    <div className="flex text-primary-200 font-semibold">
                      View Invoice
                      <svg
                        width="23"
                        height="26"
                        viewBox="0 0 23 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.5253 19.7222C13.3481 19.7222 13.1709 19.6488 13.031 19.4917C12.7605 19.1879 12.7605 18.685 13.031 18.3812L18.1977 12.5774L13.031 6.77361C12.7605 6.4698 12.7605 5.96694 13.031 5.66313C13.3014 5.35932 13.7491 5.35932 14.0195 5.66313L19.6806 12.0222C19.951 12.326 19.951 12.8288 19.6806 13.1327L14.0195 19.4917C13.8797 19.6488 13.7025 19.7222 13.5253 19.7222Z"
                          fill="#90A3BF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">ID Verification</p>
                  </div>
                  <span
                    className={`mx-2 cursor-pointer font-semibold ${
                      bookingDetail?.customer?.identity_verified
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                    {...(!bookingDetail?.customer?.identity_verified && {
                      onClick: handlePrimaryDriverVerificationButtonClick,
                    })}
                  >
                    <div className="flex">
                      {bookingDetail &&
                      bookingDetail?.customer?.identity_verified !== true
                        ? "Start Verification"
                        : "Verified"}
                      {bookingDetail?.customer?.identity_verified !== true && (
                        <svg
                          width="23"
                          height="26"
                          viewBox="0 0 23 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5253 19.7222C13.3481 19.7222 13.1709 19.6488 13.031 19.4917C12.7605 19.1879 12.7605 18.685 13.031 18.3812L18.1977 12.5774L13.031 6.77361C12.7605 6.4698 12.7605 5.96694 13.031 5.66313C13.3014 5.35932 13.7491 5.35932 14.0195 5.66313L19.6806 12.0222C19.951 12.326 19.951 12.8288 19.6806 13.1327L14.0195 19.4917C13.8797 19.6488 13.7025 19.7222 13.5253 19.7222Z"
                            fill="#90A3BF"
                          />
                        </svg>
                      )}
                    </div>
                  </span>
                </div>

                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">Insurance Verification</p>
                  </div>
                  <span
                    className={`mx-2 cursor-pointer font-semibold ${
                      bookingDetail?.customer?.insurance_verified
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                    {...(!bookingDetail?.customer?.insurance_verified && {
                      onClick: handleCanopyVerificationButtonClick,
                    })}
                  >
                    <div className="flex">
                      {bookingDetail &&
                      bookingDetail?.customer?.insurance_verified !== true
                        ? "Start Verification"
                        : "Verified"}
                      {bookingDetail?.customer?.insurance_verified !== true && (
                        <svg
                          width="23"
                          height="26"
                          viewBox="0 0 23 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5253 19.7222C13.3481 19.7222 13.1709 19.6488 13.031 19.4917C12.7605 19.1879 12.7605 18.685 13.031 18.3812L18.1977 12.5774L13.031 6.77361C12.7605 6.4698 12.7605 5.96694 13.031 5.66313C13.3014 5.35932 13.7491 5.35932 14.0195 5.66313L19.6806 12.0222C19.951 12.326 19.951 12.8288 19.6806 13.1327L14.0195 19.4917C13.8797 19.6488 13.7025 19.7222 13.5253 19.7222Z"
                            fill="#90A3BF"
                          />
                        </svg>
                      )}
                    </div>
                  </span>
                </div>

                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">Get Booking Email</p>
                  </div>
                  <span
                    className="text-primary-200 mx-2 cursor-pointer font-semibold"
                    onClick={() => sendAgreementEmail("booking_confirmation")}
                  >
                    Resend email
                  </span>
                </div>
                <div className="mb-4 flex justify-between items-center">
                  <div>
                    <p className="text-gray-800">Get Agreement</p>
                  </div>
                  <span
                    className="text-primary-200 mx-2 cursor-pointer font-semibold"
                    onClick={() => sendAgreementEmail("rental_agreement")}
                  >
                    Resend email
                  </span>
                </div>
              </div>
            </div>

            <div className="w-full mt-4">
              <h2 className="text-lg font-bold my-4">About The Car</h2>
              <div className="mb-4">
                <p className="text-gray-800">{bookingDetail?.fleet?.name}</p>
                <a href="#" className="text-gray-800"></a>
              </div>
              <div className="mb-4 flex justify-between">
                <p className="text-gray-800">License plate number</p>
                <span className="font-bold">
                  {bookingDetail?.fleet?.plate_number}
                </span>
              </div>
              <div className="mb-4 flex justify-between">
                <p className="text-gray-800">Car color</p>
                <span className="font-bold ">
                  {bookingDetail?.fleet?.color}
                </span>
              </div>
              <div className="mb-4 flex justify-between">
                <p className="text-gray-800">Build</p>
                <span className="font-bold ">{bookingDetail?.fleet?.make}</span>
              </div>
              <span className="text-sm text-gray-500 font-bold">
                Reservation ID: {bookingDetail?.id}
              </span>
            </div>
          </div>
          {bookingDetail?.note === null || bookingDetail?.note === "" ? (
            ""
          ) : (
            <div className="w-full">
              <h2 className="text-xl font-bold mb-8">Note</h2>
              <span className="text-gray-800">{bookingDetail?.note}</span>
            </div>
          )}
        </div>
        <Transition appear show={isOpen}>
          <Dialog
            as="div"
            className="relative z-10 focus:outline-none border"
            onClose={close}
          >
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto border border-primary-6000">
              <div className="flex min-h-full items-center justify-center p-4">
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 transform-[scale(95%)]"
                  enterTo="opacity-100 transform-[scale(100%)]"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 transform-[scale(100%)]"
                  leaveTo="opacity-0 transform-[scale(95%)]"
                >
                  <DialogPanel className="w-full max-w-md bg-white rounded-x p-6 backdrop-blur-2xl rounded-xl  border border-primary-6000">
                    <div className="flex justify-between mb-6">
                      <DialogTitle as="h3" className="font-medium text-xl">
                        Modify Trip
                      </DialogTitle>
                      <button onClick={close}>
                        <XMarkIcon className="h-6 w-6" />
                      </button>
                    </div>

                    <ButtonPrimary
                      className="bg-primary-500 w-full mb-5"
                      onClick={() => {
                        modifyBooking(bookingId!);
                      }}
                    >
                      Modify Booking
                    </ButtonPrimary>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
        <Transition appear show={isTotalBillModalOpen}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={handleCloseTotalBillModal}
          >
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4">
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 transform scale-95"
                  enterTo="opacity-100 transform scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 transform scale-100"
                  leaveTo="opacity-0 transform scale-95"
                >
                  <DialogPanel className="w-full max-w-md bg-white p-6 rounded-xl shadow-lg">
                    <div className="flex justify-between mb-4 border-b pb-2">
                      <DialogTitle as="h2" className="text-xl font-bold">
                        Invoice
                      </DialogTitle>
                      <button onClick={handleCloseTotalBillModal}>
                        <XMarkIcon className="h-6 w-6" />
                      </button>
                    </div>
                    <span
                      className="text-sm font-medium leading-5 text-left text-[#79808B] cursor-pointer"
                      onClick={handleOpenTotalBillModal}
                    >
                      <div className="flex flex-col space-y-4">
                        <div className="w-full mx-auto">
                          <div className="mt-4">
                            <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                              Trip Price
                            </span>
                            <div className="flex justify-between ">
                              <span>{bookingDetail?.fleet?.name}</span>
                              <div className="flex flex-col items-end">
                                <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                                  ${bookingDetail?.invoice?.car_total}
                                </span>
                                <span className="text-sm text-gray-500 dark:text-gray-400">
                                  $
                                  {bookingDetail?.invoice
                                    ?.avg_car_price_per_day + " x "}
                                  {bookingDetail?.invoice?.trip_days +
                                    "-Days  "}
                                </span>
                              </div>
                            </div>
                          </div>

                          {bookingDetail?.invoice?.location_total !==
                            "0.00" && (
                            <div className="mt-4">
                              <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                                Location Total
                              </span>
                              {bookingDetail?.invoice?.breakdown?.locations?.map(
                                (location: any) => (
                                  <>
                                    <div className="flex justify-between ">
                                      <span>{location.name}</span>
                                      <div className="flex flex-col items-end">
                                        <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                                          ${location?.total}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          )}

                          {bookingDetail?.invoice?.insurance_total !==
                            "0.00" && (
                            <div className="mt-4">
                              <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                                Insurance Total
                              </span>

                              {bookingDetail?.invoice?.breakdown?.insurance?.map(
                                (insurance: any) => (
                                  <>
                                    <div className="flex justify-between ">
                                      <span>{insurance?.name}</span>
                                      <div className="flex flex-col items-end">
                                        <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                                          ${insurance?.total}
                                        </span>
                                        <span className="text-sm text-gray-500 dark:text-gray-400">
                                          {insurance?.rate}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          )}

                          {bookingDetail?.invoice?.extras_total !== "0.00" && (
                            <div className="mt-4">
                              <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                                Trip Extras
                              </span>

                              {bookingDetail?.invoice?.breakdown?.extras?.map(
                                (extra: any) => (
                                  <>
                                    <div className="flex justify-between ">
                                      <span>{extra.name}</span>
                                      <div className="flex flex-col items-end">
                                        <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                                          ${extra.total}
                                        </span>
                                        <span>${extra.rate}</span>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          )}

                          {bookingDetail?.invoice?.tax !== "0.00" && (
                            <div className="mt-4">
                              <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                                Tax & Fee
                              </span>
                              {bookingDetail?.invoice?.breakdown?.taxes?.map(
                                (taxes: any) => (
                                  <>
                                    <div className="flex justify-between ">
                                      <span>
                                        {taxes.description
                                          ? taxes.description
                                          : taxes.name}
                                      </span>
                                      <div className="flex flex-col items-end">
                                        <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                                          ${taxes.total}
                                        </span>
                                        <span>
                                          {taxes.rate.includes("%")
                                            ? taxes.rate
                                            : `$${taxes.rate}`}
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )
                              )}
                            </div>
                          )}

                          {bookingDetail?.invoice?.discount !== "0.00" && (
                            <div className="mt-4">
                              <div className="flex justify-between ">
                                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                                  Discount
                                </span>
                                <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                                  ${bookingDetail?.invoice?.discount}
                                </span>
                              </div>
                            </div>
                          )}

                          <div className="mt-8 border-gray-200 dark:border-gray-700 pt-4">
                            <div className="flex justify-between ">
                              <div>
                                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                                  Total Rental Price
                                </span>
                                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                                  Overall price rental
                                </p>
                              </div>
                              <div>
                                <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                                  $
                                  {bookingDetail?.invoice
                                    ?.total_price_after_coupon_without_security
                                    ? bookingDetail?.invoice
                                        ?.total_price_after_coupon_without_security
                                    : bookingDetail?.invoice
                                        ?.total_price_without_security}
                                </span>
                              </div>
                            </div>
                          </div>

                          {bookingDetail?.invoice?.security_deposit_total !==
                            "0.00" && (
                            <div className="mt-4">
                              <div className="flex justify-between ">
                                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                                  Total Deposit
                                </span>
                                <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                                  $
                                  {
                                    bookingDetail?.invoice
                                      ?.security_deposit_total
                                  }
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </span>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <div className="flex justify-center items-center col-span-full py-96">
          <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
        </div>
      ) : (
        <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
          {bookingDetail &&
            bookingDetail?.customer?.identity_verified !== true && (
              <>
                <div className="min-h-22 bg-[#FF757587] flex items-center p-4">
                  <div className="flex flex-row w-full items-center justify-between max-w-6xl mx-auto">
                    <span className="text-[#FF0000] font-medium text-[14px] md:text-2xl">
                      Verify your{" "}
                      <span className="font-extrabold">identity</span> to
                      confirm your booking
                    </span>
                    <ButtonPrimary
                      onClick={handlePrimaryDriverVerificationButtonClick}
                      className="!bg-[#FF0000] !hover:bg-[#FF0000] text-white text-[12px] font-bold py-2 px-2 rounded-xl whitespace-nowrap"
                    >
                      Start Verification
                    </ButtonPrimary>
                  </div>
                </div>
              </>
            )}
          {bookingDetail &&
            bookingDetail?.customer?.insurance_verified !== true && (
              <div className="min-h-22 bg-[#FF757587] flex items-center p-4 mt-4">
                <div className="flex flex-row w-full items-center justify-between max-w-6xl mx-auto">
                  <span className="text-[#FF0000] font-medium text-[14px] md:text-2xl">
                    Verify your{" "}
                    <span className="font-extrabold">insurance</span> to confirm
                    your booking
                  </span>
                  <div className="space-x-4 flex-col md:flex-row space-y-4">
                    {/* <button className="ttnc-ButtonPrimary disabled:bg-opacity-70 bg-none border text-sm border-[#FF0000] text-[#FF0000] font-bold py-3 px-4 sm:px-6 rounded-xl">
                    Purchase Insurance
                  </button> */}
                    <ButtonPrimary
                      onClick={handleCanopyVerificationButtonClick}
                      className="!bg-[#FF0000] !hover:bg-[#FF0000] text-white text-[12px] font-bold rounded-xl px-4 py-3 whitespace-nowrap"
                    >
                      Start Verification
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            )}
          <main className="container mt-11 mb-24 lg:mb-32 ">
            <div className="max-w-6xl mx-auto">{renderContent()}</div>
          </main>
        </div>
      )}
    </>
  );
};

export default BookingDetailPage;
