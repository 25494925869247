import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import { FC } from "react";
import { useEffect } from "react";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import useNcId from "hooks/useNcId";
import { globalJson } from "global/global_json";
import NcImage from "shared/NcImage/NcImage";
import WhyUs1 from "images/why-us-1.png";
import WhyUs2 from "images/why-us-2.png";
import WhyUs3 from "images/why-us-3.png";
import WhyUs4 from "images/why-us-4.png";

export interface SectionProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
  }[];
}

const DEMO_DATA: SectionProps["data"] = [
  {
    id: 1,
    img: WhyUs1,
    title: "Car Safety and Insurance",
    desc: "Robust safety solutions to protect your asset.",
  },
  {
    id: 2,
    img: WhyUs2,
    title: "State-of-the-Art Reservation Platform",
    desc: "Optimal vehicle management solutions.",
  },
  {
    id: 3,
    img: WhyUs3,
    title: "Customer Acquisition",
    desc: "Ensuring high utilization of your vehicle.",
  },
  {
    id: 4,
    img: WhyUs4,
    title: "24/7 Customer Service",
    desc: "We are always here to assist you.",
  },
];

const WhyUs: FC<SectionProps> = ({ className = "", data = DEMO_DATA }) => {
  return (
    <div
      className={`nc-SectionClientSay relative ${className} `}
      data-nc-id="SectionClientSay"
    >
      <div>
        <Heading
          isCenter
          className="mb-2 lg:mb-4 text-neutral-900 dark:text-neutral-50"
        >
          Why Us
        </Heading>
        <div className="text-center text-gray-500 text-lg font-normal">
          Services offered by us
        </div>
      </div>

      <div className="mt-20 relative grid sm:grid-cols-4 md:grid-cols-4 gap-20  md:mx-28 mx-4">
        {data?.map((item: any) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            <NcImage containerClassName="mb-8 mx-auto" src={item.img} />

            <div className="text-center ">
              <span className="text-xl font-semibold  text-center">
                {item.title}
              </span>
              <br />
              <br />
              <span className="text-lg text-[#6B7280]  text-center">
                {item.desc}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUs;
