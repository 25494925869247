import rightImg from "images/about-hero-right.png";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionHero4 from "components/SectionHero/SectionHero";
import SiteHeader from "containers/SiteHeader";
import Logopic from "../../images/logo-small.png";
import Why from "containers/why/why";
import ReadytoDrive from "containers/PageAbout/ReadytoDrive";
import readytodrive from "images/readytodrive.png";
import { HeroSearchFormMobile } from "components/HeroSearchForm/HeroSearchFormMobile";

function PageHome3() {
  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="sm:bg-[url('images/hero-right.png')] bg-[url('images/hero-right-mobile.jpg')] bg-cover bg-no-repeat  md:bg-center w-full pb-14">
        <SiteHeader />

        <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 lg:px-12 mt-8 md:mt-24 text-center">
          <div className="container">
            <SectionHero4 />
          </div>
        </div>
        <div className="w-[360px] lg:hidden md:w-[480px] xl:w-[480px] mx-auto">
          <HeroSearchFormMobile />
        </div>
      </div>
      <div className="hidden md:flex bg-gradient-to-r from-blue-500 to-pink-500 h-[80px] lg:h-[50px] flex-col md:flex-row justify-evenly flex-wrap pl-12 ">
        <div className="text-white text-xs font-semibold flex items-center gap-16">
          <img className="w-4 h-6" src={Logopic} />
          No 1 local car rental in Tampa
        </div>
        <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
          <img className="w-4 h-6" src={Logopic} />5 star on Turo
        </div>
        <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
          <img className="w-4 h-6" src={Logopic} />
          Fastest growing car rental in Tampa
          <img className="w-4 h-6 hidden lg:block" src={Logopic} />
        </div>
      </div>
      <div className="md:hidden scroll bg-gradient-to-r from-blue-500 to-pink-500 h-[50px] flex flex-col md:flex-row justify-evenly flex-wrap pl-12 ">
        <div className="m-scroll">
          <div className=" text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
            <img className="w-4 h-6" src={Logopic} />
            No 1 local car rental in Tampa
          </div>

          <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
            <img className="w-4 h-6" src={Logopic} />5 star on Turo
          </div>
          <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
            <img className="w-4 h-6" src={Logopic} />
            Fastest growing car rental in Tampa
            <img className="w-4 h-6 hidden lg:block" src={Logopic} />
          </div>
          <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
            <img className="w-4 h-6" src={Logopic} />
            No 1 local car rental in Tampa
          </div>

          <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
            <img className="w-4 h-6" src={Logopic} />5 star on Turo
          </div>
          <div className="text-white text-xs font-semibold flex items-center gap-16 scroll-mob">
            <img className="w-4 h-6" src={Logopic} />
            Fastest growing car rental in Tampa
            <img className="w-4 h-6 hidden lg:block" src={Logopic} />
          </div>
        </div>
      </div>

      <div className="bg-[url('images/flamingo_bg.svg')] bg-[top_-1%_right_35%] md:bg-right bg-no-repeat">
        <div className="container relative py-16">
          <SectionGridFeaturePlaces />
        </div>
      </div>

      <div className="container relative" style={{ padding: "0px" }}>
        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />

        <div className="container pt-16 lg:pt-28 space-y-16 lg:space-y-28">
          <SectionHero
            rightImg={rightImg}
            heading="About Us."
            btnText=""
            subHeading={globalJson?.about_us_para}
          />
        </div>
      </div>
      <div className="bg-[url('images/flamingo_bg.svg')] bg-no-repeat md:bg-[top_20%_right_-30%] py-16 lg:py-28">
        <div className="container">
          <Why />
        </div>
      </div>
      <div className="container">
        <div className="bg-[#FFEDF9] mt-12 rounded-3xl">
          <div className="relative py-16 ">
            <SectionClientSay uniqueClassName="PageAbout_" />
          </div>
        </div>
      </div>
      <div className="bg-[url('images/flamingo_bg_flipped.svg')] mt-10 bg-no-repeat md:bg-[top_-30%_left_0%] py-16 lg:py-28">
        <div className="container">
          <div className="bg-[#FFEDF9] rounded-3xl mt-10">
            <div className="md:py-28 md:px-12 px-4 py-16">
              <ReadytoDrive
                rightImg={readytodrive}
                heading="Book your ride today!"
                btnText=""
                subHeading="If you are just arriving we can pick you up at no additional cost. Live the experience from the moment you land and we will pick you up back wherever YOU want."
                isHomePage={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHome3;
